import { createContext, useContext } from "react";
import { AttachmentUpload } from "@accurx/design";
import { Appointment } from "../api/PatientServerDTOs";

// Fields to pass in and user setter functions to be done in https://linear.app/accurx/issue/PT-107 and https://linear.app/accurx/issue/PT-108/
export type AppointmentState = {
    appointment: Appointment | null;
    requestText: string;
    requestAttachments: AttachmentUpload[];
    requestType: RequestType;
    requestTrackingId: string | null;
    setAppointment: (appointment: Appointment) => void;
    setRequestText: (requestText: string) => void;
    setRequestAttachments: (requestAttachments: AttachmentUpload[]) => void;
    setRequestType: (requestType: RequestType) => void;
    setRequestTrackingId: (requestTrackingId: string | null) => void;
    isNhsApp: boolean;
    setIsNhsApp: (isNhsApp: boolean) => void;
    clearRequestState: () => void;
    setSentRequest: (requestType: RequestType) => void;
};

export enum RequestType {
    None,
    Reschedule,
    Cancel,
    Enquire,
}

export const defaultState: AppointmentState = {
    appointment: null,
    requestText: "",
    requestAttachments: [],
    requestType: RequestType.None,
    requestTrackingId: null,
    isNhsApp: true,
    setAppointment: () => {},
    setRequestText: () => {},
    setRequestAttachments: () => {},
    setRequestType: () => {},
    setRequestTrackingId: () => {},
    setIsNhsApp: () => {},
    clearRequestState: () => {},
    setSentRequest: () => {},
};

export const AppointmentContext = createContext<AppointmentState | undefined>(
    undefined,
);
AppointmentContext.displayName = "AppointmentContext";

const AppointmentProvider = AppointmentContext.Provider;

const useAppointment = (): AppointmentState => {
    const context = useContext(AppointmentContext);

    // If we're on the client-side, ensure that anything trying to use the useAppointment hook is wrapped in the provider
    if (typeof window !== "undefined") {
        if (context === undefined) {
            throw new Error(
                "This component is a AppointmentConsumer, it must be used within an AppointmentProvider",
            );
        }

        return context;
    }

    // If we're on the server-side then don't throw an error yet the context is only expected to update once client-side
    // Instead just return a default value for now
    return context ?? defaultState;
};

export { AppointmentProvider, useAppointment };
