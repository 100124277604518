import Image from "next/legacy/image";
import * as React from "react";
import styled from "styled-components";
import { Tokens } from "@accurx/design";
import { SharedUrls } from "@accurx/shared";
import accurxLongLogo from "../../public/AccurxLogoLong.svg";
import { Footer } from "nhsuk-react-components";

const StyledDivHorizontalCenter = styled.div`
    display: flex;
    align-items: center;
`;

const StyledAccurxLink = styled(StyledDivHorizontalCenter)`
    margin-right: ${Tokens.SIZES[1]};
`;

const StyledAccurxTextWithLogo = styled.div`
    margin-right: ${Tokens.SIZES[1]};
`;

const NhsAccurxFooter = (): JSX.Element => {
    return (
        <>
            <div id="restOfThePage" style={{ height: "10vh" }} />
            <Footer>
                <Footer.List>
                    <Footer.ListItem href={SharedUrls.SecurityForPatients}>
                        Privacy policy
                    </Footer.ListItem>
                    <Footer.ListItem href={SharedUrls.TermsAndConditions}>
                        Terms of use
                    </Footer.ListItem>
                    <Footer.ListItem href="https://www.accurx.com/cookie-policy">
                        Cookie policy
                    </Footer.ListItem>
                    <Footer.ListItem href="https://drive.google.com/file/d/1gbIlK9PZM1C-I-NfKpPGpx5UY7O992GJ/view?usp=sharing">
                        Accessibility statement
                    </Footer.ListItem>
                </Footer.List>
                <Footer.Copyright>&copy; Accurx 2023</Footer.Copyright>
                <Footer.List>
                    <Footer.ListItem>
                        <StyledAccurxLink>
                            <StyledAccurxTextWithLogo>
                                <p className="nhsuk-body">Powered by:</p>
                            </StyledAccurxTextWithLogo>
                            <Image
                                src={accurxLongLogo}
                                alt="accurx logo"
                                width={100}
                                height={60}
                            />
                        </StyledAccurxLink>
                    </Footer.ListItem>
                </Footer.List>
            </Footer>
        </>
    );
};

export default NhsAccurxFooter;
