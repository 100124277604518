/** Some shared components used on appointment pages */

import styled from "styled-components";
import { Tokens } from "@accurx/design";
import { Tag } from "nhsuk-react-components";

export const StyledNhsContainerDiv = styled.div.attrs({
    className: "nhsuk-width-container nhsuk-u-padding-top-4",
})``;

export const StyledNhsFeedbackDiv = styled.div.attrs({
    className: "nhsuk-u-margin-bottom-5",
})`
    background: #43968e;
    text-align: center;
    padding: ${Tokens.SIZES[3]};
`;

export const StyledNhsTag = styled(Tag)`
    background: #f0f4f5 !important;
    border-color: #aeb7bd !important;
`;
