import React from "react";
import { useRouter } from "next/router";

import { useAppointment } from "../../context/AppointmentContext";
import {
    getNhsAppointmentsLink,
    handleGoBackToNhsAppointments,
    renderNhsHeader,
} from "../../utils/appointments/appointments.utils";

interface PageProps {
    showBackButton: boolean;
    backToAppointments: boolean;
}

const NhsAppNavigationBar = (props: PageProps): JSX.Element | null => {
    const router = useRouter();
    const { isNhsApp, appointment } = useAppointment();

    const appointmentUrl = getNhsAppointmentsLink()?.href;

    const handleGoBack = (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    ): void => {
        event.preventDefault();
        if (props.backToAppointments) {
            if (isNhsApp) {
                handleGoBackToNhsAppointments(event);
            } else {
                router.push(appointmentUrl ?? "#");
            }
        } else {
            router.back();
        }
    };

    return renderNhsHeader({
        isNhsApp,
        showBackButton: props.showBackButton,
        handleGoBack,
        appointmentUrl,
        workspaceName: appointment?.workspaceName,
    });
};

export default NhsAppNavigationBar;
