import * as React from "react";
import { ErrorSummary, Header, Tag } from "nhsuk-react-components";
import { DateHelpers, DateFormatOptions, Log } from "@accurx/shared";
import getConfig from "next/config";
import { Tokens } from "@accurx/design";
import styled from "styled-components";

import { Appointment } from "../../api/PatientServerDTOs";
import { BaseDocument, OrganisationResourceType } from "../../api/ApiDTOs";
import NhsAppNavigationBar from "../../components/appointments/NhsAppNavigationBar";
import NhsAccurxFooter from "../../components/appointments/NhsAccurxFooter";
import {
    StyledNhsContainerDiv,
    StyledNhsTag,
} from "../../styles/appointment.styles";
import TopInfoBar from "../../components/appointments/TopInfoBar";
import { PageHead } from "../../components/shared/PageHead";
import { PAGE_TITLES } from "../shared/pageTitleConstants";

/** Error page renders */

const renderErrorSummary = (
    appointmentId: string,
    errorMessageComponent?: JSX.Element,
): JSX.Element => {
    const appointmentUrl = getNhsAppointmentsLink();
    return (
        <StyledNhsContainerDiv>
            <ErrorSummary
                aria-labelledby="error-summary-title"
                role="alert"
                tabIndex={-1}
            >
                <ErrorSummary.Title id="error-summary-title">
                    There is a problem
                </ErrorSummary.Title>
                <ErrorSummary.Body>
                    {errorMessageComponent ?? (
                        <p>We could not find the page you are looking for</p>
                    )}
                    <ErrorSummary.List>
                        <ErrorSummary.Item
                            href={appointmentUrl?.href ?? "#"}
                            onClick={(e) => handleGoBackToNhsAppointments(e)}
                        >
                            Go back
                        </ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </StyledNhsContainerDiv>
    );
};

export const renderAppointmentsErrorPage = (
    appointmentId: string,
    errorMessage?: JSX.Element,
) => {
    return (
        <>
            <NhsAppNavigationBar
                showBackButton={true}
                backToAppointments={true}
            />
            {errorMessage && renderErrorSummary(appointmentId, errorMessage)}
            {!errorMessage && renderErrorSummary(appointmentId)}
        </>
    );
};

// Because we're in an appointment url but don't actually have an appointment
// in the context, we use a "stateless" error page for 404/500 pages
export const renderStatelessAppointmentsErrorPage = (isNhsApp: boolean) => {
    const handleGoBack = (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    ): void => {
        event.preventDefault();
        if (isNhsApp) {
            handleGoBackToNhsAppointments(event);
        }
    };
    return (
        <>
            {renderNhsHeader({
                isNhsApp,
                showBackButton: true,
                handleGoBack,
                appointmentUrl: undefined,
                workspaceName: undefined,
            })}
            {renderErrorSummary("")}
            <NhsAccurxFooter />
        </>
    );
};

type ErrorDetailsProps = {
    errorBody: string;
    errorLinkText: string;
    handleLinkClick: () => void;
};

export const renderUserActionErrorState = ({
    errorBody,
    errorLinkText,
    handleLinkClick,
}: ErrorDetailsProps) => {
    return (
        <ErrorSummary
            className="nhsuk-u-margin-bottom-4"
            id="errorSummary"
            aria-labelledby="error-summary-title"
            role="alert"
            tabIndex={-1}
        >
            <ErrorSummary.Title id="error-summary-title">
                There is a problem
            </ErrorSummary.Title>
            <ErrorSummary.Body>
                <p>{errorBody}</p>
                <ErrorSummary.List>
                    <ErrorSummary.Item
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            handleLinkClick();
                        }}
                    >
                        {errorLinkText}
                    </ErrorSummary.Item>
                </ErrorSummary.List>
            </ErrorSummary.Body>
        </ErrorSummary>
    );
};

/** Appointment card renders */

export const renderAppointmentDateTime = (
    appointmentTime: string,
): JSX.Element => {
    return (
        <>
            <p className="nhsuk-heading-m nhsuk-u-margin-bottom-0">
                {DateHelpers.formatDate(
                    appointmentTime,
                    DateFormatOptions.NHS_DATE_LONG_WITH_DAY,
                )}
            </p>
            <p className="nhsuk-heading-m nhsuk-u-margin-top-2 nhsuk-u-margin-bottom-0">
                {DateHelpers.formatTime(
                    appointmentTime,
                    DateFormatOptions.NHS_TIME_SHORT_12_HR_DOT,
                )}
            </p>
        </>
    );
};

export const renderAppointmentTag = (appointment: Appointment): JSX.Element => {
    if (appointment.isCancelled) {
        return <Tag color="red">Cancelled</Tag>;
    }
    if (
        appointment.rescheduleRequestInProgress ||
        appointment.cancelRequestInProgress
    ) {
        return <StyledNhsTag color="grey">Request sent</StyledNhsTag>;
    }
    if (appointment.generalRequestInProgress) {
        return <StyledNhsTag color="grey">Query sent</StyledNhsTag>;
    }
    return <StyledNhsTag color="grey">Booked</StyledNhsTag>;
};

export const renderAppointmentLocation = (
    appointmentLocation: string,
): JSX.Element => {
    return (
        <>
            <p className="nhsuk-heading-s nhsuk-u-margin-bottom-0 nhsuk-u-margin-top-4">
                Location
            </p>
            <p className="nhsuk-body">{appointmentLocation}</p>
        </>
    );
};

export const renderAppointmentType = (appointmentType: string): JSX.Element => {
    return (
        <>
            <p className="nhsuk-heading-s nhsuk-u-margin-bottom-0 nhsuk-u-margin-top-4">
                Appointment type
            </p>
            <p className="nhsuk-body">{appointmentType}</p>
        </>
    );
};

export const renderAppointmentClinician = (
    appointmentClinician: string | null,
): JSX.Element => {
    return (
        <>
            {appointmentClinician !== null && (
                <>
                    <p className="nhsuk-heading-s nhsuk-u-margin-bottom-0 nhsuk-u-margin-top-4">
                        Clinician
                    </p>
                    <p className="nhsuk-body">{appointmentClinician}</p>
                </>
            )}
        </>
    );
};

/** Document card utils and renders */

export const getStringdocumentTypeName = (
    docType: OrganisationResourceType,
): string | null => {
    switch (docType) {
        case OrganisationResourceType.Map:
            return "Map";
        case OrganisationResourceType.AdviceAndGuidance:
            return "Advice and Guidance";
        case OrganisationResourceType.Instructions:
            return "Instructions";
        case OrganisationResourceType.None:
            return null;
        case OrganisationResourceType.Other:
            return null;
    }
};

export const getFormattedDocumentDateCreated = (
    document: BaseDocument,
): string => {
    return DateHelpers.formatDate(
        document.createdAt,
        DateFormatOptions.DATE_SHORT_WITH_SPACES,
    );
};

export const getBase64StringFromBlob = (blob: Blob): Promise<string> => {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            const dataUrl = reader.result as string;
            const base64 = dataUrl.split("base64,");
            resolve(base64[1]);
        };
        reader.readAsDataURL(blob);
    });
};

//Only block default if inside NHS App otherwise default action will use browser nav
//We have to double check the nhs app here in case the value set in context is null (As in the error state flow)
export const handleGoBackToNhsAppointments = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
): void => {
    const isNhsApp = -1 !== window?.navigator?.userAgent?.indexOf("nhsapp");
    if (isNhsApp) {
        e.preventDefault();
        window?.nhsapp?.navigation?.goToPage(
            window?.nhsapp?.navigation?.AppPage?.APPOINTMENTS,
        );
    }
};

export const getNhsAppointmentsLink = (): URL | null => {
    const { publicRuntimeConfig } = getConfig();

    const urlBase = publicRuntimeConfig.NHS_APP_BASE_URL;

    if (urlBase !== undefined) {
        return new URL(`${urlBase}/patient/appointments`);
    }

    Log.error("NHS Base Url not set, falling back to prod URL");
    return null;
};

/** Navbar */

const StyledNavbar = styled.nav`
    background-color: ${Tokens.COLOURS.greyscale.white};
    width: 100%;
    border-bottom: solid 1px ${Tokens.COLOURS.greyscale.silver};

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
`;

const StyledDivHorizontalCenter = styled.div`
    display: flex;
    align-items: center;
`;

type NhsHeaderProps = {
    isNhsApp: boolean;
    showBackButton: boolean;
    handleGoBack: (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    ) => void;
    appointmentUrl: string | undefined;
    workspaceName: string | undefined;
};

export const renderNhsHeader = ({
    isNhsApp,
    showBackButton,
    handleGoBack,
    appointmentUrl,
    workspaceName,
}: NhsHeaderProps): JSX.Element => (
    <>
        <PageHead text={PAGE_TITLES.appointment} />
        {!isNhsApp && (
            <Header>
                <Header.Container>
                    {appointmentUrl && (
                        <>
                            <Header.Logo href={appointmentUrl} />
                            {workspaceName && (
                                <Header.ServiceName href={appointmentUrl} long>
                                    {workspaceName}
                                </Header.ServiceName>
                            )}
                        </>
                    )}
                    {!appointmentUrl && <Header.Logo />}
                </Header.Container>
            </Header>
        )}
        {isNhsApp && showBackButton && (
            <StyledNavbar className="nhsuk-u-padding-4">
                <StyledDivHorizontalCenter>
                    <a
                        href=""
                        className="nhsuk-back-link__link"
                        onClick={(e) => handleGoBack(e)}
                    >
                        <svg
                            className="nhsuk-icon nhsuk-icon__chevron-left"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                            height="24"
                            width="24"
                        >
                            <path d="M8.5 12c0-.3.1-.5.3-.7l5-5c.4-.4 1-.4 1.4 0s.4 1 0 1.4L10.9 12l4.3 4.3c.4.4.4 1 0 1.4s-1 .4-1.4 0l-5-5c-.2-.2-.3-.4-.3-.7z"></path>
                        </svg>
                        Back
                    </a>
                </StyledDivHorizontalCenter>
            </StyledNavbar>
        )}
        <TopInfoBar />
    </>
);
