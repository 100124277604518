import * as React from "react";
import styled from "styled-components";

// Colour from https://service-manual.nhs.uk/design-system/styles/colour
const StyledInfoBar = styled.aside`
    background-color: #d8dde0;
    width: 100%;
    border-bottom: 1px solid #979797;
`;

const TopInfoBar = (): JSX.Element => {
    return (
        <StyledInfoBar className="nhsuk-u-padding-top-2 nhsuk-u-padding-bottom-2">
            <div className="nhsuk-width-container">
                Your hospital provides this service
            </div>
        </StyledInfoBar>
    );
};

export default TopInfoBar;
